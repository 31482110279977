import {Checkbox} from "@design-system/ui-kit";
import {useRecoilState} from "recoil";
import {sendGaEvent} from "core/utils/sendGaEvent";
import {EGaCategories, EGaEvents, EGaTypes} from "core/typings/enums";
import {Trans} from "react-i18next";

import {isAcceptTermsAndConditionsAtom} from "../../../../core/store";
import {useLocalization} from "../../../../domain/i18n/providers/i18n";

interface AcceptTermsAndConditionsProps {
    eventOption2?: EGaTypes;
    platform: string,
}

export const AcceptTermsAndConditions: React.FC<AcceptTermsAndConditionsProps> = ({platform, eventOption2}) => {
    const [accept, setAccept] = useRecoilState(isAcceptTermsAndConditionsAtom);
    const {lang} = useLocalization();

    const handleChange = (newValue: boolean) => {
        setAccept(newValue);
        sendGaEvent(EGaEvents.CHECKBOX_ACCEPT_TERMS, {
            event_option1: EGaCategories.REQUEST_PAYOUT,
            event_option2: eventOption2,
            event_option4: newValue ? `${platform};enable` : `${platform};disable`,
        });
    };

    return <div>
        <Checkbox onChange={handleChange}
                  checked={accept}
                  label={
                      <span>
                <Trans i18nKey={'payment.acceptTermsAndConditions'}
                       components={[<a href={`/terms/CapyFast_Terms_${lang}.pdf`}
                                       className={'underline'} target={'_blank'}
                                       rel="noreferrer"/>]}>
                    I accept the terms and conditions of the CapyFast
                </Trans>
                    </span>
                  }/>
    </div>
}
