import React from 'react';
import ReactDOM from 'react-dom/client';
import '@design-system/ui-kit/dist/main.css';

import './styles/style.scss';
import App from './App';

import {createRoutesFromChildren, matchRoutes, useLocation, useNavigationType,} from "react-router-dom";
import * as Sentry from "@sentry/react";

import reportWebVitals from './reportWebVitals';
import {ENV} from "./core/environment";

Sentry.init({
    dsn: "https://f0ae1228277344bdb4561af6a2651f6a@sentry.mitgo.tech/319",
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
    ],
    tracesSampleRate: 1.0,
    environment: ENV.MODE,
    beforeSend(event) {
        if (ENV.MODE === 'development') {
            event.tags = {...event.tags, 'development': true} || {};
            event.tags.automatically_resolved = true; // Добавляем тег
        }
        return event;
    },
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
        <App/>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
