import {createContext, ReactNode, useContext, useEffect} from "react";
import * as Sentry from "@sentry/react";

import {useUserService} from "../services/user-config";


const UserContext = createContext<ReturnType<typeof useUserService> | null>(null);

export const UserProvider = ({children}: {children: ReactNode}) => {
    const context = useUserService();;

    useEffect(() => {
        Sentry.setUser({
            id: context?.payload?.user_id ?? undefined,
            email: context?.payload?.email ?? undefined,
            username: context?.payload?.sso_id ?? undefined,
        })
    }, [context.payload])

    // useEffect(() => {
    //     if(!!ENV.CLARITY){
    //         clarity.init(ENV.CLARITY);
    //
    //         if(context?.payload?.user_id) clarity.identify(String(context?.payload?.user_id), {
    //             email: context.payload?.email,
    //             sso: context.payload?.sso_id
    //         })
    //     }
    // }, [])

    if(!context) throw new Error('tier2 provider not set');

    return <UserContext.Provider value={context}>
        {children}
    </UserContext.Provider>
}

export const useUser = () => {
    const context = useContext(UserContext);

    if(!context) throw new Error('tier2 provider not set');

    return context;
}
