import React from 'react';
import {AppProvider} from "core/app/app-context";
import {AppRoutes} from "core/router";
import {RecoilRoot} from "recoil";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {broadcastQueryClient} from '@tanstack/query-broadcast-client-experimental'
import * as Sentry from "@sentry/react";

import {PayoutFlowProvider} from "./domain/payout/providers/payout-flow";
import {UserProvider} from "./domain/user/providers/user";

const queryClient = new QueryClient();

broadcastQueryClient({
    queryClient,
    broadcastChannel: 'capyfast-broadcast',
})

function App() {

    return (

        <RecoilRoot>
            <QueryClientProvider client={queryClient}>
                <AppProvider>
                    <UserProvider>
                        <PayoutFlowProvider>
                            <AppRoutes/>
                        </PayoutFlowProvider>
                    </UserProvider>
                </AppProvider>
            </QueryClientProvider>
        </RecoilRoot>
    );
}

export default Sentry.withProfiler(App);
